<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-card body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <v-chart class="chart sentiment-pie" :option="sentimentPieOptions" />
          <div class="ml-3">
            <h5 class="font-weight-bold mb-0 text-success">POSITIF <span
                class="text-success font-size-sm font-weight-normal"> (+2.9%)</span></h5>
            <span class="badge badge-mark border-success mr-1"></span> <span class="text-muted">Jun 16, 10:00
              am</span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <ul class="nav nav-pills justify-content-end mb-0">
            <li class="nav-item"><a href="#right-pill1" class="nav-link bg-indigo-400 active"
                data-toggle="tab">Days</a></li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Week</a></li>
            <li class="nav-item"><a href="#right-pill2" class="nav-link" data-toggle="tab">Month</a></li>
          </ul>
          <div id="tickets-status"></div>
          <div class="ml-3">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text"><i class="icon-calendar22"></i></span>
              </span>
              <date-range-picker
                class="form-control"
                control-container-class=""
                opens="left"
                append-to-body
                :date-range="dateRange"
                style="min-width: 180px"
              />
            </div>
          </div>
        </div>
      </b-card>
      <b-card>
        <template #header>
          <b-card-title class="font-weight-bold" title-tag="h6">Grafik Sentimen Data</b-card-title>
        </template>
        <div class="chart-container">
          <v-chart :option="sentimentTrendsOptions" class="chart has-fixed-height" />
        </div>
      </b-card>
      <b-row>
        <b-col md="9">
          <div class="card">
            <div class="card-body">
              <div class="cat_switch">
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-earth"></i>
                  </div>
                  <p>All</p>
                  <h3>1428</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic bg_fb">
                    <i class="icon-facebook"></i>
                  </div>
                  <p>Facebook</p>
                  <h3>0</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic bg_ig">
                    <i class="icon-instagram"></i>
                  </div>
                  <p>Instagram</p>
                  <h3>11</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic ">
                    <i class="icon-twitter"></i>
                  </div>
                  <p>Twitter</p>
                  <h3>0</h3>
                </div>
                 <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-newspaper"></i>
                  </div>
                  <p>News</p>
                  <h3>191</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-youtube"></i>
                  </div>
                  <p>Youtube</p>
                  <h3>191</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic">
                    <i class="icon-newspaper2"></i>
                  </div>
                  <p>Blog</p>
                  <h3>215</h3>
                </div>
                <div class="list_btn">
                  <div class="wrap_ic ">
                    <i class="icon-sphere"></i>
                  </div>
                  <p>Web</p>
                  <h3>340</h3>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-7">
                  
                </div>
                <div class="col-md-5">
                  <div class="form-group mb-0">
                    <b-input-group>
                      <b-form-input class="border-right-0" placeholder="Search..." />
                      <template #append>
                        <b-btn class="bg-indigo-400">Search</b-btn>
                      </template>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <label> Filter by</label>
                  <b-form-select v-model="selFilter" :options="filterOptions" />
                </div>
                <div class="col-md-12 mt-3">
                  <label> Filter by Sentiment</label>
                  <b-form-select v-model="selSentiment" :options="sentimentOptions" />
                </div>
                <!--<div class="col-md-12 mt-3">
                  <label>Filter by Score</label>
                  <input type="text" class="form-control ion-height-helper" id="ion-start" data-fouc>
                </div>-->
              </div>
            </div>
          
          </div>
        </b-col>
      </b-row>
      <div
        class="card_mention"
        v-for="(mention, index) in mentions"
        :key="index"
        :class="{
          'bg_positive': mention.sentiment == 'Positif',
          'bg_negative': mention.sentiment == 'Negatif',
        }"
      >
        <b-row>
          <b-col md="3">
            <div class="d-flex align-items-center">
              <div class="form-check pl-1 mb-0 mr-1">
                <label class="form-check-label">
                  <b-form-checkbox class="form-check-input-styled" />
                </label>
              </div>
              <div class="mr-3">
                <b-button class="btn-icon btn-sm rounded-round"
                  :class="{
                    'bg_ig': mention.type == 'ig',
                    'bg_news': mention.type == 'news',
                    'bg_yt': mention.type == 'yt',
                    'bg_tw': mention.type == 'tw',
                  }"
                >
                  <i
                    :class="{
                      'icon-instagram': mention.type == 'ig',
                      'icon-newspaper': mention.type == 'news',
                      'icon-youtube': mention.type == 'yt',
                      'icon-twitter': mention.type == 'tw',
                    }"></i>
                </b-button>
              </div>
              <div>
               <a href="#" class="text-default font-weight-bold letter-icon-title">{{ mention.username }}</a>
                <div class="text-muted font-size-sm">
                  <span
                    :class="`badge badge-mark mr-1 ${mention.status == 'Active' ? 'border-blue':'border-gray'}`"></span> {{ mention.status }}
                </div>
                <!-- <div class="rating_score" v-html="starMaker(5, mention.rating)">
                </div> -->
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <div class="card_content">
              <h3><a href="">{{ mention.title }}</a></h3>
              <h4><a href="">{{ mention.source }}</a></h4>
              <p>
                {{ mention.description }}
              </p>
            </div>
          </b-col>
          <b-col md="2">
            <a href="#" class="text-default">
              <span class="text-muted"><i class="icon-calendar mr-2"></i> {{ mention.date | moment('lll') }}</span>
            </a>
            <div class="row">
              <div class="col-md-8 mt-3">
                <b-form-select class="mb-2" size="sm" v-model="mention.mark" :options="marking" />
                <b-form-select
                  size="sm"
                  :disabled="mention.mark != 'ubah'"
                  v-model="mention.sentiment"
                  :options="sentiments"
                />
              </div>
              <div class="col-md-4 mt_32">
                <!-- <div class="list-icons">
                  <b-dropdown
                    right
                    toggle-class="p-0 list-icons-item"
                    toggle-tag="a"
                    variant="transparent"
                  >
                    <template #button-content>
                      <i class="icon-list-unordered"></i>
                    </template>
                    <b-dropdown-item><i class="icon-sync"></i> Update data</b-dropdown-item>
                    <b-dropdown-item><i class="icon-list-unordered"></i> Detailed log</b-dropdown-item>
                    <b-dropdown-item><i class="icon-pie5"></i> Statistics</b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item><i class="icon-cross3"></i> Clear lists</b-dropdown-item>
                  </b-dropdown>
                </div> -->
                <b-button size="sm" variant="outline-secondary" class="btn-icon">
                  <i class="icon-trash-alt"></i>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-card>
        <ul class="pagination pagination-flat align-self-center">
          <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
          <li class="page-item active"><a href="#" class="page-link">1</a></li>
          <li class="page-item"><a href="#" class="page-link">2</a></li>
          <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
          <li class="page-item"><a href="#" class="page-link">4</a></li>
          <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
        </ul>
      </b-card>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>
<script>
import chartDatas from '@/dummies/chartDatas.js'
import {
    use
  } from "echarts/core";
  import {
    CanvasRenderer
  } from "echarts/renderers";
  import {
    PieChart,
    LineChart,
  } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  } from "echarts/components";
  import VChart from 'vue-echarts'
import GlobalVue from '../../libs/Global.vue';

  use([
    CanvasRenderer,
    PieChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
  ])
export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return{
      dateRange: {},
      sentiments: ['Netral', 'Positif', 'Negatif'],
      marking: [
        { text: 'Tandai', value: '' },
        { text: 'Ubah Sentimen', value: 'ubah' },
        { text: 'Tangkal Sentimen', value: 'tangkal' },
      ],
      mentions: [
        {
          type: 'tw',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'BacaDiBaBe',
          description: 'Prabowo Tawarkan Anggaran Pertahanan Dialihkan untuk Berantas Covid, Jokowi Beri Jawaban',
          source: 'twitter.com',
          date: '2021-08-17',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'tw',
         // username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'KD_Indones',
          description: 'ROMO KEMERDEKAAN!!!️🤍 Mari nikmati waktu santai bersama keluarga dengan paket BAGE WAYVI O-nya. Paket berisi: - 3 Pempek Kapal Selam - 3 Pempek Telur Kecil - 3 Pempek Lenjer Kecil - 3 Pempek Adaan #indonesiamerdeka #kemerdekaanindonesia #indonesiamaju #kulinernusantara #pempekjakarta #pempekpaketmurah #kapalselam',
          source: 'instagram.com',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Positif',
        },
        {
          type: 'news',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'RAPBN 2022 Kementerian yang dipimpin Prabowo Subi',
          description: 'Nota Keuangan RAPBN 2022 Presiden Joko Widodo tidak menyinggung rencana anggaran pertahanan tahun depan. Kementerian Pertahanan yang dipimpin Menteri Pertahanan Prabowo Subianto',
          source: ' nasional.kontan.co.id',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'news',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'Prabowo Tawarkan Anggaran Pertahanan Dialihkan unt',
          description: 'Tawarkan Anggaran Pertahanan Dialihkan untuk Berantas Covid, Jokowi Beri Jawaban. Rio Rizky Pangestu 17 Agustus 2021, 09:23',
          source: ' pikiran-rakyat.com',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'yt',
          //username: '@integradesign',
          status: 'Active',
         // rating: 3,
          title: 'SmartLapak',
          description: 'Dr Connie Rahakundini Bakrie pengamat militer berbicara tegas dan jelas soal sistem pertahanan maritim indonesia sekarang tidak sebanding dengan ...',
          source: 'instagram.com',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'tw',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'abufauzan1924',
          description: 'Pernah Tawarkan Anggaran Pertahanan, Prabowo: Pak Jokowi Sedang All Out https://bit.ly/3m4VPER',
          source: 'instagram.com',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Positif',
        },
        {
          type: 'tw',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'OposisiCerdas',
          description: 'Prabowo Ngaku Pernah Usul ke Jokowi Agar Anggaran Pertahanan Dialihkan Untuk Penanganan Covid-19 https://www.oposisicerdas.com/2021/08/prabowo-ngaku-pernah-usul-ke-jokowi.html',
          source: 'twitter.com',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'tw',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'rmol_id',
          description: '@Prabowo Ngaku Pernah Usul ke @Jokowi Agar Anggaran Pertahanan Dialihkan Untuk Penanganan Covid-19 https://politik.rmol.id/read/2021/08/16/500718/prabowo-ngaku-pernah-usul-ke-jokowi-agar-anggaran-pertahanan-dialihkan-untuk-penanganan-covid-19',
          source: 'twitter.com',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Positif',
        },
        {
          type: 'news',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'Anggaran Pertahanan Israel Naik 2,15 Miliar Dolar AS',
          description: 'Anggaran pertahanan tersebut meningkat sebesar 2,15 miliar dolar AS dari tahun 2020. YERUSALEM — Menteri pertahanan dan keuangan Israel akan mengalokasikan 58 miliar shekel atau 17,8 miliar dolar AS untuk keperluan, pertahanan pada 2022. Anggaran pertahanan tersebut meningkat sebesar 2,15 miliar dolar AS dari tahun sebelumnya.',
          source: ' harianaceh.co.id.com',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Netral',
        },
        {
          type: 'news',
          //username: '@integradesign',
          status: 'Active',
          //rating: 3,
          title: 'Top! Prabowo Boyong 6 Jet Tempur Baru Rp 3,4 T dari K',
          description: 'Perusahaan Misterius PT TMI & Belanja Alutsista Rp1.760 T. Ramai Anggota DPR Desak Connie Ungkap Mr M Mafia Alutsista. Reaksi Prabowo Soal Heboh Mister M Sang Mafia Alutsista. Video Gerilya .',
          source: 'cnbcindonesia.com',
          date: '2020-09-21 15:00',
          mark: '',
          sentiment: 'Netral',
        },
      ],
      sentimentPieOptions: chartDatas.sentimentPieOptions,
      mediaPieOptions: chartDatas.mediaPieOptions,
      sentimentTrendsOptions: chartDatas.sentimentTrendsOptions,
      filterOptions: [
        { text: '-- Tampilkan Semua --', value: '' },
        { text: 'Media Sosial', value: 'sm' },
        { text: 'Web', value: 'w' },
      ],
      selFilter: '',
      sentimentOptions: [
        { text: '-- Tampilkan Semua --', value: '' },
        { text: 'Positif', value: 'pos' },
        { text: 'Negatif', value: 'neg' },
        { text: 'Netral', value: 'net' },
      ],
      selSentiment: '',
    }
  }
}
</script>